<template>
  <div>
    <div class="banner" :style="cover">
      <h1 class="banner-title">{{ fansUserName }}</h1>
    </div>
    <!-- 主页文章 -->
    <v-row class="home-container">
      <!-- 博主信息 -->
      <v-col md="3" cols="12" class="d-md-block d-none">
        <div class="blog-wrapper">
          <v-card class="animated zoomIn blog-card mt-5">
            <div class="author-wrapper">
              <!-- 博主头像 -->
              <v-avatar size="110">
                <img class="author-avatar" :src="fansAvatar" />
              </v-avatar>
              <div style="font-size: 1.375rem">
                {{ fansUserName }}
              </div>
              <div style="font-size: 0.875rem;">
                {{ fansRoleName }}
              </div>
            </div>
            <!-- 博客信息 -->
            <div class="blog-info-wrapper">
              <div class="blog-info-data">
                <router-link to="/archives" tag="a" target="_blank">
                  <div style="font-size: 0.875rem">文章</div>
                  <div style="font-size: 1.25rem">
                    {{ articleCount }}
                  </div>
                </router-link>
              </div>
              <div class="blog-info-data">
                <router-link :to=" '/fans/' + fansUserId "  tag="a" target="_blank">
                  <div style="font-size: 0.875rem">粉丝</div>
                  <div style="font-size: 1.25rem">
                    {{ fansCount }}
                  </div>
                </router-link>
              </div>
              <div class="blog-info-data">
                <router-link to="/categories" tag="a" target="_blank">
                  <div style="font-size: 0.875rem">分类</div>
                  <div style="font-size: 1.25rem">
                    {{ categoryCount }}
                  </div>
                </router-link>
              </div>
              <div class="blog-info-data">
                <router-link to="/categories" tag="a" target="_blank">
                  <div style="font-size: 0.875rem">收藏</div>
                  <div style="font-size: 1.25rem">
                    {{ favoriteCount }}
                  </div>
                </router-link>
              </div>
            </div>
            <!-- 收藏按钮 -->
            <div class="blog-info-wrapper-button">
              <div class="blog-info-data">
                <a class="collection-btn" @click="tip = true">
                  关注
                </a>
              </div>
            </div>
          </v-card>
        </div>
      </v-col>

      <v-col md="9" cols="12">
        <v-card>
          <div style="display: flex;margin-top: 20px;padding: 1rem 1rem">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
              <el-menu-item index="0">全部</el-menu-item>
              <el-menu-item index="3" >已发布</el-menu-item>
              <el-menu-item index="2" >待发布</el-menu-item>
              <el-menu-item index="1" >草稿中</el-menu-item>
            </el-menu>
          </div>
        </v-card>
        <el-empty v-if="articleList.length === 0" description="暂无数据..."></el-empty>
        <v-card class="animated zoomIn article-card" style="border-radius: 12px 8px 8px 12px" v-for="(item, index) of articleList" :key="item.articleId">
          <!-- 文章封面图 -->
          <div :class="isRight(index)">
            <router-link :to="'/articles/' + item.articleId">
              <v-img class="on-hover" width="100%" height="100%" :src="item.cover"/>
            </router-link>
          </div>
          <!-- 文章信息 -->
          <div class="article-wrapper">
            <div style="line-height:1.4">
              <div>
                <router-link :to="'/articles/' + item.articleId" tag="a" target="_blank">
                  {{ item.title }}
                </router-link>
                <span v-if=" item.views >= 1000" style="font-size:12px;border-radius:3px;border: 1px solid  #f70;text-align: center"><font style="color: #f70;">千次阅读</font></span>
              </div>
            </div>
            <div class="article-info">
              <strong>作者</strong>：{{ item.authorName }}
            </div>
            <div class="article-info">
              <v-icon size="14">mdi-calendar-month-outline</v-icon>
              {{ item.createTime }}
            </div>
            <div class="article-info">
              <!-- 文章分类 -->
              <router-link :to="'/categories/' + item.categoryId" tag="a" target="_blank">
                <v-icon size="14">mdi-inbox-full</v-icon>
                {{ item.categoryName }}
              </router-link>
              <span class="separator">|</span>
              <!-- 文章标签 -->
              <v-icon size="14">mdi-tag-multiple</v-icon>{{ item.labelName }}
            </div>
            <!-- 文章摘要 -->
            <div class="article-content">
              {{ item.summary }}
            </div>
          </div>
        </v-card>

        <!-- 分页按钮 -->
        <v-pagination v-if="count > 0"
            color="#00C4B6"
            v-model="current"
            :length="Math.ceil(count / param.pageSize)"
            total-visible="7"
        />
      </v-col>


    </v-row>
    <!-- 提示消息 -->
    <v-snackbar v-model="tip" top color="#49b1f5" :timeout="2000">
      按CTRL+D 键将本页加入书签
    </v-snackbar>
  </div>
</template>

<script>
import { getArticleCategoryNums, getUserArticle } from "@/api/article";
import { userinfo } from "@/api/user";

export default {
  created() {
    this.init();
    this.fetchArticleCategoryNums();
    this.fetchWebsiteConfig();
    this.getViewArticle();
    this.getCategoryList();
    this.timer = setInterval(this.runTime, 1000);
  },

  data: function() {
    return {
      count: 0,
      activeIndex: '0',
      userId: "",
      userName: "",
      userRole: "",
      userAvatar: "",
      param: {
        pageNo: 1,
        pageSize: 10,
        status: "",
        userId: ""
      },
      pageNo: 2,
      articleCount: 0,
      categoryCount: 0,
      fansCount: 0,
      favoriteCount: 0,
      current: 1,
      articleList: [],
      fansUserName: "",
      fansHomepageCover: "",
      fansAvatar: "",
      fansRoleName: "",
      fansUserId: this.$route.params.fansId
    };
  },
  methods: {
    // 获取用户的文章数、分类数
    fetchArticleCategoryNums() {
      getArticleCategoryNums(this.fansUserId).then(res => {
        if (res.code === 200) {
          this.articleCount = res.data.articleCount;
          this.categoryCount = res.data.categoryCount;
          this.favoriteCount = res.data.favoriteCount;
          this.fansCount = res.data.fansCount;
        }
      })
    },

    // 初始化
    init() {
      this.fetchUserInfo();
      this.fetchUserArticle();
    },

    // 获取用户的文章
    fetchUserArticle() {
      if (this.param.status === 0) {
        this.param.status = "";
      }
      this.param.userId = this.fansUserId;
      this.param.pageNo = this.current;
      getUserArticle(this.param).then(res => {
        if (res.code === 200) {
          this.articleList = res.data.list;
          this.count = res.data.total;
        }
      })
    },

    // 获取用户信息
    fetchUserInfo() {
      userinfo(this.fansUserId).then(res => {
        if (res.code === 200) {
          this.fansRoleName = res.data.roleName;
          this.fansUserName = res.data.userName;
          this.fansAvatar = res.data.avatar;
          this.fansHomepageCover = res.data.homepageCover;
        }
      })
    },

    handleSelect(key, keyPath) {
      this.param.status = key;
      this.fetchUserArticle();
    },
  },

  computed: {
    isRight() {
      return function(index) {
        if (index % 2 === 0) {
          return "article-cover left-radius";
        }
        return "article-cover right-radius";
      };
    },

    cover() {
      return "background: url(" + this.fansHomepageCover + ") center center / cover no-repeat";
    }
  },

  watch: {
    current(value) {
      this.current = value;
      this.fetchUserArticle();
    }
  },
};
</script>

<style scoped>

.blog-contact a {
  color: #fff !important;
}

.card-info-social a {
  font-size: 1.5rem;
}

.article-wrapper {
  font-size: 14px;
}
@media (min-width: 760px) {
  .blog-title {
    font-size: 2.5rem;
  }
  .blog-intro {
    font-size: 1.5rem;
  }
  .blog-contact {
    display: none;
  }
  .home-container {
    max-width: 1200px;
    margin: 330px auto 28px auto;
    padding: 0 5px;
  }
  .article-card {
    display: flex;
    align-items: center;
    height: 280px;
    width: 100%;
    margin-top: 20px;
  }
  .article-cover {
    overflow: hidden;
    height: 100%;
    width: 45%;
  }
  .on-hover {
    transition: all 0.6s;
  }
  .article-card:hover .on-hover {
    transform: scale(1.1);
  }
  .article-wrapper {
    padding: 0 2.5rem;
    width: 55%;
  }
  .article-wrapper a {
    font-size: 1.5rem;
    transition: all 0.3s;
  }
}
@media (max-width: 759px) {
  .blog-title {
    font-size: 26px;
  }
  .blog-contact {
    font-size: 1.25rem;
    line-height: 2;
  }
  .home-container {
    width: 100%;
    margin: calc(100vh - 66px) auto 0 auto;
  }
  .article-card {
    margin-top: 1rem;
  }
  .article-cover {
    border-radius: 8px 8px 0 0 !important;
    height: 230px !important;
    width: 100%;
  }
  .article-cover div {
    border-radius: 8px 8px 0 0 !important;
  }
  .article-wrapper {
    padding: 1.25rem 1.25rem 1.875rem;
  }
  .article-wrapper a {
    font-size: 1.25rem;
    transition: all 0.3s;
  }
}

.scroll-down i {
  font-size: 3rem;
}
.article-wrapper a:hover {
  color: #8e8cd8;
}
.article-info {
  font-size: 95%;
  color: #858585;
  line-height: 2;
  margin: 0.375rem 0;
}
.article-info a {
  font-size: 95%;
  color: #858585 !important;
}
.article-content {
  line-height: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.blog-wrapper {
  position: sticky;
  top: 10px;
}
.blog-card {
  line-height: 2;
  padding: 1.25rem 1.5rem;
}
.author-wrapper {
  text-align: center;
}
.blog-info-wrapper {
  display: flex;
  justify-self: center;
  padding: 0.875rem 0;
}
.blog-info-wrapper-button {
  display: flex;
  justify-self: center;
}
.blog-info-data {
  flex: 1;
  text-align: center;
}
.blog-info-data a {
  text-decoration: none;
}
.collection-btn {
  text-align: center;
  z-index: 1;
  font-size: 14px;
  position: relative;
  display: block;
  background-color: #49b1f5;
  color: #fff !important;
  height: 32px;
  line-height: 32px;
  transition-duration: 1s;
  transition-property: color;
}
.collection-btn:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #ff7242;
  content: "";
  transition-timing-function: ease-out;
  transition-duration: 0.5s;
  transition-property: transform;
  transform: scaleX(0);
  transform-origin: 0 50%;
}
.collection-btn:hover:before {
  transition-timing-function: cubic-bezier(0.45, 1.64, 0.47, 0.66);
  transform: scaleX(1);
}
.author-avatar {
  transition: all 0.5s;
}
.author-avatar:hover {
  transform: rotate(360deg);
}

@keyframes scroll-down-effect {
  0% {
    top: 0;
    opacity: 0.4;
    filter: alpha(opacity=40);
  }
  50% {
    top: -16px;
    opacity: 1;
    filter: none;
  }
  100% {
    top: 0;
    opacity: 0.4;
    filter: alpha(opacity=40);
  }
}
.big i {
  color: #f00;
  animation: big 0.8s linear infinite;
}
@keyframes big {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.guanzhu ul li {
  font-size: 12px;
  margin-bottom: 10px;
  background: #fff;
  color: #525252;
  line-height: 40px;
  padding: 0 0 0 34px;
  border: 1px solid #ddd;
  border-radius: 2px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.article-item img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  object-fit: cover;
}

.article-item img:hover {
  transform: scale(1.1);
}

.content-title a {
  transition: all 0.2s;
  font-size: 95%;
}

.content-title a:hover {
  color: #2ba1d1;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

</style>
